import './loading.scss';

export const Loading = ({ isLoading, error }) => {
  // Handle the loading state
  if (isLoading) {
    return (
      <section className="left">
        <div className="card">
          <div className="card-header">
            Loading page....
          </div>
          <div className="card-body">
            Loading...
          </div>
        </div>
      </section>
    );
  }
  // Handle the error state
  if (error) {
    console.log(error);
    const text = `Page loading error..... ${error.message}`;
    return <div className="main-error oswald-light">{text}</div>;
  }
  return null;
};
