import { Component } from 'react';
import packageJson from '../package.json';

global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

class CacheBuster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            latestVersion: null,
            refreshCacheAndReload: () => {
                console.log('Clearing cache and hard reloading...');
                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then((names) => {
                        for (const name of names) caches.delete(name);
                    });
                }

                // delete browser cache and hard reload
                window.location.reload(true);
            },
        };
    }

    componentDidMount() {
        fetch('/meta.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },

        })
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    console.log(`Running version - ${latestVersion}. Please refresh your browser to clear cache`);
                    this.setState({ latestVersion, loading: false, isLatestVersion: false });
                } else {
                    console.log(`Running latest version - ${latestVersion}. No cache refresh needed.`);
                    this.setState({ latestVersion, loading: false, isLatestVersion: true });
                }
            });
    }

    render() {
        const {
            latestVersion, loading, isLatestVersion, refreshCacheAndReload,
        } = this.state;
        return this.props.children({
            latestVersion, loading, isLatestVersion, refreshCacheAndReload,
        });
    }
}

export default CacheBuster;
