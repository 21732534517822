import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import loadable from '@loadable/component';
import { Loading } from './loading';
import Errors from './errors';
import './scss/main.scss';
// import { ContextProvider } from './state/all';


const AsyncHome = loadable(() => import(/* webpackChunkName: "home", webpackPrefetch: true */ './containers/home'), {
  fallback: <Loading />,
});
const AsyncReport = loadable(() => import(/* webpackChunkName: "report", webpackPrefetch: true */ './containers/report'), {
  fallback: <Loading />,
});

const App = props => {
  const { version } = props;
  return (
    <Router basename="/sim">
      <Errors>
        <Switch>
          <Route exact path="/" component={AsyncHome} />
          <Route exact path="/report" component={AsyncReport} />
        </Switch>
      </Errors>
      <ToastContainer />
    </Router>
  );
};

export default App;
