import { render } from 'react-dom';
import WebFont from 'webfontloader';

import './custom.scss';
import 'font-awesome/css/font-awesome.min.css';

import Root from './root';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Load all the webfonts
WebFont.load({
  google: {
    families: [
      'Open Sans:300,400,600,700',
      'Oswald:300,400,500,600',
    ],
  },
});

render(
  <Root />, document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
reportWebVitals();
