import { Component } from 'react';
import './error-page.scss';

export default class Errors extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { errorInfo, error } = this.state;

    if (errorInfo) {
      // Error path
      return (
        <section className="error-page oswald-light animate__animated animate__fadeInDown">

          <div className="row">
            <h4 className="title">Errors</h4>
          </div>

          <div className="row">

            <section className="col-md-12 col-sm-12 col-xs-12">
              {error && error.toString()}
              <br /><br />
              {errorInfo.componentStack}
            </section>
            <section className="col-md-12 col-sm-12 col-xs-12">
              Please check these errors
            </section>
          </div>

        </section>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
